.header {
  /* display: none; */

  /* @media (min-width: $mantine-breakpoint-sm) { */
  display: block;
  height: 100%;
  background-color: var(--mantine-color-body);
  /* border-bottom: rem(1px) solid
      light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)); */
  /* } */
}

.inner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  display: block;
  line-height: 3;
  padding: rem(8px) rem(12px);
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  }
}

.linkLabel {
  margin-right: rem(5px);
}

.logo {
  height: var(--logo-height);
  position: relative;
  top: calc((var(--logo-height) - rem(60px)) / 2);
  width: auto;
}
