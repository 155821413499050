.Footer_footer__o4_Ec {
  display: block;
  height: 100%;
  background-color: var(--mantine-color-gray-1);
}
  [data-mantine-color-scheme='dark'] .Footer_footer__o4_Ec {
  background-color: var(--mantine-color-dark);
}

.Footer_inner__w0_wv {
  display: flex;
  justify-content: space-between;
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-sm);
  flex-wrap: wrap;
  height: 100%;
}

.Footer_links__xHnvg {
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  -webkit-box-pack: start;
  justify-content: space-between;
}

.Footer_statement__MWbir {
  flex-grow: 1;
  font-style: italic;
  text-align: center;
  flex-basis: 100%;
  padding: 0rem;
}

.Header_header__pXml_ {
  /* display: none; */

  /* @media (min-width: $mantine-breakpoint-sm) { */
  display: block;
  height: 100%;
  background-color: var(--mantine-color-body);
  /* border-bottom: rem(1px) solid
      light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)); */
  /* } */
}

.Header_inner__BWOOt {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header_link___GWEF {
  display: block;
  line-height: 3;
  padding: calc(0.5rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale));
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: var(--mantine-color-gray-7);
}

[data-mantine-color-scheme='dark'] .Header_link___GWEF {
  color: var(--mantine-color-dark-0);
}

.Header_link___GWEF {
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;
}

@media (hover: hover) {

  .Header_link___GWEF:hover {
    background-color: var(--mantine-color-gray-0);
  }
    [data-mantine-color-scheme='dark'] .Header_link___GWEF:hover {
    background-color: var(--mantine-color-dark-6);
  }
}

@media (hover: none) {

  .Header_link___GWEF:active {
    background-color: var(--mantine-color-gray-0);
  }
    [data-mantine-color-scheme='dark'] .Header_link___GWEF:active {
    background-color: var(--mantine-color-dark-6);
  }
}

.Header_linkLabel__Vevr_ {
  margin-right: calc(0.3125rem * var(--mantine-scale));
}

.Header_logo__sUWiP {
  height: var(--logo-height);
  position: relative;
  top: calc((var(--logo-height) - calc(3.75rem * var(--mantine-scale))) / 2);
  width: auto;
}

.ColorSchemeToggle_icon__hAqJq {
  width: calc(1.375rem * var(--mantine-scale));
  height: calc(1.375rem * var(--mantine-scale));
}

[data-mantine-color-scheme='dark'] .ColorSchemeToggle_dark__Hnbgo {
    display: none;
}

[data-mantine-color-scheme='light'] .ColorSchemeToggle_dark__Hnbgo {
    display: block;
}

[data-mantine-color-scheme='light'] .ColorSchemeToggle_light__B5SHF {
    display: none;
}

[data-mantine-color-scheme='dark'] .ColorSchemeToggle_light__B5SHF {
    display: block;
}

.Shell_control__cr_Iq {
  display: block;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-md);
  font-weight: 500;
}

  @media (hover: hover) {

  .Shell_control__cr_Iq:hover {
    background-color: var(--mantine-color-gray-0);
  }
    [data-mantine-color-scheme='dark'] .Shell_control__cr_Iq:hover {
    background-color: var(--mantine-color-dark-6);
  }
}

  @media (hover: none) {

  .Shell_control__cr_Iq:active {
    background-color: var(--mantine-color-gray-0);
  }
    [data-mantine-color-scheme='dark'] .Shell_control__cr_Iq:active {
    background-color: var(--mantine-color-dark-6);
  }
}

@layer mantine {.m_17884d0f {
  --carousel-height: auto;
  position: relative;
}

.m_a2dae653 {
  height: var(--carousel-height);
  overflow: hidden;
}

.m_fcd81474 {
  display: flex;
  flex-direction: row;
  height: var(--carousel-height);
}

:where([data-include-gap-in-size]) .m_fcd81474:where([data-orientation='vertical']) {
      margin-bottom: calc(var(--carousel-slide-gap) * -1);
    }

:where([data-include-gap-in-size]) .m_fcd81474:where([data-orientation='horizontal']) {
      margin-inline-end: calc(var(--carousel-slide-gap) * -1);
    }

.m_fcd81474:where([data-orientation='vertical']) {
    flex-direction: column;
  }

.m_39bc3463 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  pointer-events: none;
}

.m_39bc3463:where([data-orientation='vertical']) {
    inset-inline-start: calc(50% - var(--carousel-control-size) / 2);
    top: 0;
    bottom: 0;
    flex-direction: column;
    padding: var(--carousel-controls-offset) 0;
  }

.m_39bc3463:where([data-orientation='horizontal']) {
    inset-inline-start: 0;
    inset-inline-end: 0;
    top: calc(50% - var(--carousel-control-size) / 2);
    flex-direction: row;
    padding: 0 var(--carousel-controls-offset);
  }

.m_64f58e10 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--carousel-control-size);
  min-height: var(--carousel-control-size);
  border-radius: var(--carousel-control-size);
  pointer-events: all;
  background-color: var(--mantine-color-white);
  color: var(--mantine-color-black);
  box-shadow: var(--mantine-shadow-md);
  border: 1px solid var(--mantine-color-gray-3);
  transition: opacity 100ms ease;
}

:where([data-mantine-color-scheme='light']) .m_64f58e10 {
    opacity: 0.85;
}

:where([data-mantine-color-scheme='dark']) .m_64f58e10 {
    opacity: 0.65;
}

@media (hover: hover) {

  .m_64f58e10:hover {
    opacity: 1;
  }
}

@media (hover: none) {

  .m_64f58e10:active {
    opacity: 1;
  }
}

.m_71ea3ab1 {
  position: absolute;
  display: flex;
  justify-content: center;
  gap: calc(0.5rem * var(--mantine-scale));
  pointer-events: none;
}

.m_71ea3ab1:where([data-orientation='vertical']) {
    bottom: 0;
    top: 0;
    inset-inline-end: var(--mantine-spacing-md);
    flex-direction: column;
  }

.m_71ea3ab1:where([data-orientation='horizontal']) {
    bottom: var(--mantine-spacing-md);
    inset-inline-start: 0;
    inset-inline-end: 0;
    flex-direction: row;
  }

.m_eae68602 {
  pointer-events: all;
  border-radius: var(--mantine-radius-xl);
  box-shadow: var(--mantine-shadow-sm);
  opacity: 0.6;
  background-color: var(--mantine-color-white);
}

@media (hover: hover) {

  .m_eae68602:hover {
    opacity: 1;
  }
}

@media (hover: none) {

  .m_eae68602:active {
    opacity: 1;
  }
}

.m_eae68602:where([data-active]) {
    opacity: 1;
  }

.m_eae68602:where([data-orientation='vertical']) {
    width: calc(0.3125rem * var(--mantine-scale));
    height: calc(1.5625rem * var(--mantine-scale));
  }

.m_eae68602:where([data-orientation='horizontal']) {
    width: calc(1.5625rem * var(--mantine-scale));
    height: calc(0.3125rem * var(--mantine-scale));
  }

.m_d98df724 {
  position: relative;
  flex: 0 0 var(--carousel-slide-size, 100%);
}

.m_17884d0f:where([data-include-gap-in-size]) .m_d98df724:where([data-orientation='vertical']) {
      padding-bottom: var(--carousel-slide-gap);
    }

.m_17884d0f:where([data-include-gap-in-size]) .m_d98df724:where([data-orientation='horizontal']) {
      padding-inline-end: var(--carousel-slide-gap);
    }

.m_17884d0f:where(:not([data-include-gap-in-size])) .m_d98df724:where([data-orientation='vertical']) {
      margin-bottom: var(--carousel-slide-gap);
    }

.m_17884d0f:where(:not([data-include-gap-in-size])) .m_d98df724:where([data-orientation='horizontal']) {
      margin-inline-end: var(--carousel-slide-gap);
    }
}
