.footer {
  display: block;
  height: 100%;
  background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark));
}

.inner {
  display: flex;
  justify-content: space-between;
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-sm);
  flex-wrap: wrap;
  height: 100%;
}

.links {
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  -webkit-box-pack: start;
  justify-content: space-between;
}

.statement {
  flex-grow: 1;
  font-style: italic;
  text-align: center;
  flex-basis: 100%;
  padding: 0rem;
}
